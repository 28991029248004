/* eslint-disable */
import { createApp } from 'vue'

import App from '@/App.vue'
import store from '@/store'

require('@/subscriber')

import router from '@/router'

import '@/assets/css/nucleo-icons.css'
import '@/assets/css/nucleo-svg.css'
import SoftUIDashboard from '@/soft-ui-dashboard'

const appInstance = createApp(App)

import moment from 'moment'
appInstance.config.globalProperties.$moment = moment

// appInstance.config.globalProperties.$filters = {
//     VLMoment(value, format) {
//         return value
//     }
// }

import axios from 'axios'
import { API_URL, UI_URL } from '@/config'
axios.defaults.baseURL = API_URL

const token = localStorage.getItem('vl-token')
if (token) {
    axios.defaults.headers.common['Authorization'] = token
}

import Ripple from 'vue-ripple-directive'
appInstance.directive('ripple', Ripple)

import { VBTooltip } from 'bootstrap-vue'
appInstance.directive('b-tooltip', VBTooltip)

import Clipboard from 'v-clipboard'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import VueImg from 'v-img'

const options = {
    position: 'top-left',
    timeout: 2000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false
}

function storageChange(event) {
    if (event.key === 'vl-token' && event.newValue === null) {
        window.location = '/login'
    }
}
window.addEventListener('storage', storageChange, false)

appInstance.mixin({
    data() {
        return {
            UI_URL,
            sortBy: 'created_at',
            sortDesc: true,
        }
    },
    computed: {
        sortConfig() {
            return {
                sortBy: this.sortBy,
                sortDesc: this.sortDesc
            }
        }
    },
    methods: {
        setCurrentPagePagination(data) {
            return {
                current_page: data?.data?.current_page || 1,
                from: data?.data?.from || 1,
                last_page: data?.data?.last_page || 1,
                per_page: data?.data?.per_page || 20,
                to: data?.data?.to || 1,
                total: data?.data?.total || 0
            }
        },
    }
})

store.dispatch('auth/attempt', { token }).then(() => {

    const user = store.state['auth'].user

    appInstance
        .use(store)
        .use(router)
        .use(Clipboard)
        .use(VueImg)
        .use(SoftUIDashboard)
        .use(Toast, options)
        .mount('#app')
})
