/* eslint-disable */
import axios from 'axios'

export default {

    namespaced: true,

    state: {
        user: null,
        token: null,
        accountType: null,
    },

    getters: {
        authenticated(state) {
            return state.user && state.token
        },

        user(state) {
            return state.user
        },

        accountType(state) {
            return state.user['accountType']
        },
    },

    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },

        SET_USER(state, user) {
            state.user = user
        },
    },

    actions: {

        async signIn({ dispatch }, credentials) {
            let response = await axios.post('login', credentials)
            dispatch('attempt', response.data.data)

            return Promise.resolve(response)
        },

        async attempt({ commit, dispatch, state }, payload) {
            if (payload && payload.token) {
                localStorage.setItem('vl-token', payload.token)
                commit('SET_TOKEN', payload.token)
            }

            if (!state.token) return;

            try {
                let response = await axios.get('me')
                return commit('SET_USER', response.data.data)
            } catch (error) {
                commit('SET_TOKEN', null)
                commit('SET_USER', null)
            }
        },

        async logout({ commit }) {
            let response = await axios.post('logout')
            commit('SET_TOKEN', null)
            commit('SET_USER', null)

            return Promise.resolve(response)
        },

        async getProfileAction({ commit }) {
            let response = await axios.get('me')
            return commit('SET_USER', response.data.data)
        },

    }

}
