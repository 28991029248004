/* eslint-disable */
import store from '@/store'
import multiguard from 'vue-router-multiguard'
import { createRouter, createWebHistory } from 'vue-router'

const requireAuth = (to, from, next) => {
	if (store.getters['auth/user'] && store.getters['auth/authenticated']) {
		return next()
	}

	return next({ name: 'Login' })
}

const isAdminAccount = (to, from, next) => {
	if (store.getters['auth/authenticated'] && store.getters['auth/accountType'] && store.getters['auth/accountType'] === 'admin') {
		return next()
	}

	return next({ name: 'Permission' })
}

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/Login.vue')
	},
	{
		path: '/not-allowed',
		name: 'Permission',
		component: () => import('@/views/Permission.vue')
	},
	{
		path: '/',
		name: 'Layout',
		beforeEnter: multiguard([requireAuth, isAdminAccount]), // Apply the authentication middleware
		component: () => import('@/layouts/default'),
		redirect: '/dashboard',
		meta: {
			title: '',
			requireAuth: true,
		},
		children: [{
			path: '/dashboard',
			name: 'Dashboard',
			component: () => import('@/views/Dashboard'),
			meta: {
				title: '',
				requireAuth: true,
			}
		},

		{
			path: '/venues',
			name: 'Venues',
			component: () => import('@/views/Venues/index'),
			meta: {
				title: '',
				requireAuth: true,
			}
		},
		{
			path: '/venues/:id',
			name: 'VenueContainer',
			props: true,
			meta: {
				title: '',
				requireAuth: true,
			},
			component: () => import('@/views/Venues/Container'),
			children: [{
				path: '',
				name: 'VenueInfo',
				component: () => import('@/views/Venues/Info'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			},
			{
				path: 'reservations',
				name: 'VenueReservations',
				component: () => import('@/views/Venues/Reservations'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			},
			{
				path: 'bookings',
				name: 'VenueBookings',
				component: () => import('@/views/Venues/Bookings'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			},
			{
				path: 'events',
				name: 'VenueEvents',
				component: () => import('@/views/Venues/Events'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			},
			{
				path: 'events/:eventId',
				name: 'VenueEventInfo',
				component: () => import('@/views/Venues/EventInfo'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			},
			{
				path: 'bottle-order',
				name: 'VenueBottleOrder',
				component: () => import('@/views/Venues/Bottles'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			},
			{
				path: 'careers',
				name: 'VenueJobs',
				component: () => import('@/views/Venues/Careers'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			},
			{
				path: 'gift-cards',
				name:  'VenueGiftCards',
				component: () => import('@/views/Venues/GiftCards'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			},
			{
				path: 'reviews',
				name: 'VenueReviews',
				component: () => import('@/views/Venues/Reviews'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			}]
		},

		{
			path: '/members',
			name: 'Members',
			component: () => import('@/views/Members/index'),
			meta: {
				title: '',
				requireAuth: true,
			}
		},

		{
			path: '/members/:id',
			name: 'MemberContainer',
			props: true,
			meta: {
				title: '',
				requireAuth: true,
			},
			component: () => import('@/views/Members/Container'),
			children: [{
				path: '',
				name: 'MemberInfo',
				component: () => import('@/views/Members/Info'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			},
			{
				path: 'reservations',
				name: 'MemberReservations',
				component: () => import('@/views/Members/Reservations'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			},
			{
				path: 'bookings',
				name: 'MemberBookings',
				component: () => import('@/views/Members/Bookings'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			},
			{
				path: 'tickets',
				name: 'MemberTickets',
				component: () => import('@/views/Members/Tickets'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			},
			{
				path: 'bottle-orders',
				name: 'MemberBottleOrder',
				component: () => import('@/views/Members/Bottles'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			},
			{
				path: 'careers',
				name: 'MemberJobs',
				component: () => import('@/views/Members/Careers'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			},
			{
				path: 'gift-cards',
				name: 'MemberGiftCards',
				component: () => import('@/views/Members/GiftCards'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			},
			{
				path: 'reviews',
				name: 'MemberReviews',
				component: () => import('@/views/Members/Reviews'),
				props: true,
				meta: {
					title: '',
					requireAuth: true,
				}
			}]
		},

		// {
		// 	path: '/deals',
		// 	name: 'Deals',
		// 	component: () => import('@/views/Venues/index'),
		// 	meta: {
		// 		title: '',
		// 		requireAuth: true,
		// 	}
		// },

		{
			path: '/reservations',
			name: 'Reservations',
			component: () => import('@/views/Operations/Reservations'),
			meta: {
				title: 'Reservations',
				requireAuth: true,
			}
		},
		{
			path: '/bookings',
			name: 'Bookings',
			component: () => import('@/views/Operations/Bookings'),
			meta: {
				title: 'Bookings',
				requireAuth: true,
			}
		},
		{
			path: '/bottles',
			name: 'Bottles',
			component: () => import('@/views/Operations/Bottles'),
			meta: {
				title: '',
				requireAuth: true,
			}
		},
		{
			path: '/gift-cards',
			name: 'GiftCards',
			component: () => import('@/views/Operations/GiftCards'),
			meta: {
				title: '',
				requireAuth: true,
			}
		},
		{
			path: '/reviews',
			name: 'Reviews',
			component: () => import('@/views/Operations/Reviews'),
			meta: {
				title: '',
				requireAuth: true,
			}
		},
		{
			path: '/careers',
			name: 'Careers',
			component: () => import('@/views/Operations/Careers'),
			meta: {
				title: '',
				requireAuth: true,
			}
		},
		{
			path: '/mailing-lists',
			name: 'MailingLists',
			component: () => import('@/views/Operations/MailingLists'),
			meta: {
				title: '',
				requireAuth: true,
			}
		},
		{
			path: '/events',
			name: 'Events',
			component: () => import('@/views/Operations/Events'),
			meta: {
				title: '',
				requireAuth: true,
			}
		},
		{
			path: '/events/:id',
			name: 'EventInfo',
			props: true,
			component: () => import('@/views/Operations/EventInfo'),
			meta: {
				title: '',
				requireAuth: true,
			}
		},

		// TODO:
		{
			path: '/visitors',
			name: 'Visitors',
			component: () => import('@/views/Venues/index'),
			meta: {
				title: '',
				requireAuth: true,
			}
		},
		{
			path: '/steps-logs',
			name: 'StepsLogs',
			component: () => import('@/views/Venues/index'),
			meta: {
				title: '',
				requireAuth: true,
			}
		},
		{
			path: '/settings',
			name: 'Settings',
			component: () => import('@/views/Venues/index'),
			meta: {
				title: '',
				requireAuth: true,
			}
		}]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	linkActiveClass: 'active',
})

router.beforeResolve((to, from, next) => {

	if (to.matched.some(url => url.meta.requireAuth)) {
		if (store.getters['auth/user'] && store.getters['auth/authenticated']) {
			return next()
		} else {
			return next({ name: 'Login' })
		}
	}

	if (to.name === 'Login' && store.getters['auth/authenticated']) {
		return next({
			name: 'Dashboard'
		})
	}

	return next()

})

export default router
