/* eslint-disable */
import axios from 'axios'

export default {

    namespaced: true,

    state: {
        analytics: {}
    },

    getters: {
        analytics(state) {
            return state.analytics
        },
    },

    mutations: {
        SET_ANALYTICS(state, payload) {
            state.analytics = payload
        },
    },

    actions: {
        async getDashboardAnalyticsAction({ commit }) {
            let response = await axios.get('admin/dashboard/analytics')

            commit('SET_ANALYTICS', response.data.data)

            return Promise.resolve(response)
        },
    }
}
