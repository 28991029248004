/* eslint-disable */
export default {
    created(el, binding, vnode, prevVnode) {
        el.onerror = function () {}
    }, // new
    beforeMount() { },
    mounted(element) {

        const imgType = element.dataset.type || 'venue'

        if (!element.src) {
            element.src = imgType === 'venue' ? require(`@/assets/img/venue-no-image.png`) : require(`@/assets/img/member-no-image.png`)
        }

        element.onerror = function () {
            element.src = imgType === 'venue' ? require(`@/assets/img/venue-no-image.png`) : require(`@/assets/img/member-no-image.png`)
        }
    },
    beforeUpdate() { }, // new
    updated(element) {
        element.onerror = function () {
            element.src = imgType === 'venue' ? require(`@/assets/img/venue-no-image.png`) : require(`@/assets/img/member-no-image.png`)
        }
    },
    beforeUnmount() { }, // new
    unmounted() { }
}
