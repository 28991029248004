/* eslint-disable */
import axios from 'axios'

export default {

    namespaced: true,

    state: {
        venue: {},
        events: [],
        venues: [],
        tickets: [],
        bottles: [],
        careers: [],
        reviews: [],
        bookings: [],
        giftCards: [],
        pagination: {},
        reservations: [],
    },

    getters: {
        venues(state) {
            return state.venues
        },

        venue(state) {
            return state.venue
        },

        reviews(state) {
            return state.reviews
        },

        careers(state) {
            return state.careers
        },

        bookings(state) {
            return state.bookings
        },

        reservations(state) {
            return state.reservations
        },

        events(state) {
            return state.events
        },

        tickets(state) {
            return state.tickets
        },

        bottles(state) {
            return state.bottles
        },

        giftCards(state) {
            return state.giftCards
        },
    },

    mutations: {
        SET_VENUES(state, venues) {
            state.venues = venues
        },

        SET_VENUE(state, venue) {
            state.venue = venue
        },

        SET_REVIEWS(state, reviews) {
            state.reviews = reviews
        },

        SET_CAREERS(state, careers) {
            state.careers = careers
        },

        SET_EVENTS(state, payload) {
            state.events = payload
        },

        SET_EVENT_TICKETS(state, payload) {
            state.tickets = payload
        },

        SET_BOOKINGS(state, bookings) {
            state.bookings = bookings
        },

        SET_RESERVATIONS(state, reservations) {
            state.reservations = reservations
        },

        SET_BOTTLES(state, payload) {
            state.bottles = payload
        },

        SET_GIFT_CARDS(state, payload) {
            state.giftCards = payload
        },
    },

    actions: {

        async getVenuesAction({ commit }, { current_page:page = 1, per_page:limit = 20, sortBy = 'created_at', sortDesc = 'DESC' }) {
            let response = await axios.get(`admin/venue?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDesc}`)

            commit('SET_VENUES', response.data.data.data)

            return Promise.resolve(response)
        },

        async getVenueInfoAction({ commit }, id) {
            await axios.get(`admin/venue/${id}`).then(({ data }) => {
                return commit('SET_VENUE', data.data)
            })
        },

        async getVenueReviewsAction({ commit }, { id, current_page:page = 1, per_page:limit = 20}) {
            let response = await axios.get(`admin/venue/${id}/reviews?page=${page}&limit=${limit}`)

            commit('SET_REVIEWS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getVenueCareersAction({ commit }, { id, current_page: page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/venue/${id}/careers?page=${page}&limit=${limit}`)

            commit('SET_CAREERS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getVenueBookingsAction({ commit }, { id, current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/venue/${id}/bookings?page=${page}&limit=${limit}`)

            commit('SET_BOOKINGS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getVenueEventsAction({ commit }, { id, current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/venue/${id}/events?page=${page}&limit=${limit}`)

            commit('SET_EVENTS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getVenueEventTicketOrdersAction({ commit }, { id, eventId, current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/venue/${id}/tickets/${eventId}?page=${page}&limit=${limit}`)

            commit('SET_EVENT_TICKETS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getVenueReservationsAction({ commit }, { id, current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/venue/${id}/reservations?page=${page}&limit=${limit}`)

            commit('SET_RESERVATIONS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getVenueBottlesAction({ commit }, { id, current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/venue/${id}/bottles?page=${page}&limit=${limit}`)

            commit('SET_BOTTLES', response.data.data.data)

            return Promise.resolve(response)
        },

        async getVenueGiftCardsAction({ commit }, { id, current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/venue/${id}/gift-cards?page=${page}&limit=${limit}`)

            commit('SET_GIFT_CARDS', response.data.data.data)

            return Promise.resolve(response)
        },

    }

}
