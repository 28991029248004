import axios from 'axios'
import store from '@/store'

store.subscribe(mutation => {
    switch (mutation.type) {
        case 'auth/SET_TOKEN':
            if (mutation.payload) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`
                localStorage.setItem('vl-token', mutation.payload)
            } else {
                axios.defaults.headers.common['Authorization'] = null
                localStorage.removeItem('vl-token')
            }
            break;

        case 'auth/SET_REFRESH_TOKEN':
            if (mutation.payload) {
                localStorage.setItem('vl-refresh-token', mutation.payload)
            } else {
                localStorage.removeItem('vl-refresh-token')
            }
            break;

        default:
            break;
    }
})
