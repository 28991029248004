import '@/assets/js/tooltip.js'
import '@/assets/js/nav-pills.js'
import '@/assets/scss/soft-ui-dashboard.scss'
import globalDirectives from '@/plugins/globalDirectives'

export default {
	install(Vue) {
		Vue.use(globalDirectives)
	},
}
