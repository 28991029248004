import { createStore } from 'vuex'
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

import AuthModule from '@/store/modules/auth'
import VenueModule from '@/store/modules/venue'
import MemberModule from '@/store/modules/member'
import DashboardModule from '@/store/modules/dashboard'
import OperationsModule from '@/store/modules/operations'

export default createStore({
	state: {
		hideConfigButton: false,
		isPinned: true,
		showConfig: false,
		isTransparent: '',
		isRTL: false,
		color: '',
		isNavFixed: true,
		isAbsolute: false,
		showNavs: true,
		showSidenav: true,
		showNavbar: true,
		showFooter: true,
		showMain: true,
		navbarFixed: 'position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4',
		absolute: 'position-absolute px-4 mx-0 w-100 z-index-2',
		bootstrap,
		previewImgTopPosition: 0,
		previewImgLeftPosition: 0,
		previewImgUrl: null,
	},

	mutations: {
		toggleConfigurator(state) {
			state.showConfig = !state.showConfig
		},
		navbarMinimize(state) {
			const sidenav_show = document.querySelector('.g-sidenav-show')
			if (sidenav_show.classList.contains('g-sidenav-hidden')) {
				sidenav_show.classList.remove('g-sidenav-hidden')
				sidenav_show.classList.add('g-sidenav-pinned')
				state.isPinned = true
			} else {
				sidenav_show.classList.add('g-sidenav-hidden')
				sidenav_show.classList.remove('g-sidenav-pinned')
				state.isPinned = false
			}
		},
		sidebarType(state, payload) {
			state.isTransparent = payload
		},
		cardBackground(state, payload) {
			state.color = payload
		},
		navbarFixed(state) {
			if (state.isNavFixed === false) {
				state.isNavFixed = true
			} else {
				state.isNavFixed = false
			}
		},
		toggleEveryDisplay(state) {
			state.showNavbar = !state.showNavbar
			state.showSidenav = !state.showSidenav
			state.showFooter = !state.showFooter
		},
		toggleHideConfig(state) {
			state.hideConfigButton = !state.hideConfigButton
		},
	},

	actions: {
		toggleSidebarColor({ commit }, payload) {
			commit('sidebarType', payload)
		},
		setCardBackground({ commit }, payload) {
			commit('cardBackground', payload)
		},
	},

	getters: {},

	modules: {
		auth: AuthModule,
		venue: VenueModule,
		member: MemberModule,
		dashboard: DashboardModule,
		operations: OperationsModule,
	},

})
