export const VERSION = 'v1'

// LOCAL
// export const appUrl = 'http://127.0.0.1:8000/'
// export const UI_URL = 'http://127.0.0.1:8080/'

// LIVE
// export const appUrl = 'https://api.venuelista.com/'
// export const UI_URL = 'https://venuelista.com/'

// TEST SERVER
export const appUrl = 'https://api.venuelista.venuewebsitedesign.com/'
export const UI_URL = 'https://venuelista.venuewebsitedesign.com/'

// NOTE: Don't Change This
export const API_URL = `${appUrl}api/`
