import noImage from '@/directives/no-image.js'
import imgHover from '@/directives/img-hover.js'

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const globalDirectives = {
    install(Vue) {
        Vue.directive('no-image', noImage)
        Vue.directive('img-hover', imgHover)
    }
}

export default globalDirectives
