/* eslint-disable */
import store from '@/store'

export default {
    created(element, binding, vnode, prevVnode) {
        element.onmouseover = () => {
            /*
                previewImgTopPosition: 0,
                previewImgLeftPosition: 0,
                previewImgUrl: null,
            */

            store.state.previewImgTopPosition = element.offsetParent.offsetTop + 250
            store.state.previewImgLeftPosition = element.offsetLeft + 350
            store.state.previewImgUrl = element.src
        }

        element.onmouseout = () => {
            store.state.previewImgUrl = null
        }
    }, // new
    beforeMount() { },
    mounted(element) { },
    beforeUpdate() { }, // new
    updated() { },
    beforeUnmount() { }, // new
    unmounted() { }
}
