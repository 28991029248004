/* eslint-disable */
import axios from 'axios'

export default {

    namespaced: true,

    state: {
        event: {},
        events: [],
        careers: [],
        reviews: [],
        tickets: [],
        bottles: [],
        bookings: [],
        giftCards: [],
        pagination: {},
        reservations: [],
        mailingLists: [],
    },

    getters: {

        event(state) {
            return state.event
        },

        events(state) {
            return state.events
        },

        tickets(state) {
            return state.tickets
        },

        reviews(state) {
            return state.reviews
        },

        careers(state) {
            return state.careers
        },

        bookings(state) {
            return state.bookings
        },

        bottles(state) {
            return state.bottles  
        },

        reservations(state) {
            return state.reservations
        },

        giftCards(state) {
            return state.giftCards
        },

        mailingLists(state) {
            return state.mailingLists
        },
    },

    mutations: {
        SET_EVENTS(state, payload) {
            state.events = payload
        },

        SET_TICKETS(state, payload) {
            state.tickets = payload
        },

        SET_EVENT(state, payload) {
            state.event = payload
        },

        SET_REVIEWS(state, payload) {
            state.reviews = payload
        },

        SET_CAREERS(state, payload) {
            state.careers = payload
        },

        SET_BOOKINGS(state, payload) {
            state.bookings = payload
        },

        SET_BOTTLES(state, payload) {
            state.bottles = payload
        },

        SET_RESERVATIONS(state, payload) {
            state.reservations = payload
        },

        SET_GIFT_CARDS(state, payload) {
            state.giftCards = payload
        },

        SET_MAILING_LISTS(state, payload) {
            state.mailingLists = payload
        },
    },

    actions: {

        async getReservationsAction({ commit }, { current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/operations/reservations?page=${page}&limit=${limit}`)

            commit('SET_RESERVATIONS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getBookingsAction({ commit }, { current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/operations/bookings?page=${page}&limit=${limit}`)

            commit('SET_BOOKINGS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getBottlesAction({ commit }, { current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/operations/bottles?page=${page}&limit=${limit}`)

            commit('SET_BOTTLES', response.data.data.data)

            return Promise.resolve(response)
        },

        async getGiftCardsAction({ commit }, { current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/operations/gift-cards?page=${page}&limit=${limit}`)

            commit('SET_GIFT_CARDS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getCareersAction({ commit }, { current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/operations/careers?page=${page}&limit=${limit}`)

            commit('SET_CAREERS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getEventsAction({ commit }, { current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/operations/events?page=${page}&limit=${limit}`)

            commit('SET_EVENTS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getEventInfoAction({ commit }, id) {
            let response = await axios.get(`admin/operations/event/${id}`)

            commit('SET_EVENT', response.data.data)

            return Promise.resolve(response)
        },

        async getEventTicketsInfoAction({ commit }, id) {
            let response = await axios.get(`admin/operations/tickets/${id}`)

            commit('SET_TICKETS', response.data.data)

            return Promise.resolve(response)
        },

        async getReviewsAction({ commit }, { current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/operations/reviews?page=${page}&limit=${limit}`)

            commit('SET_REVIEWS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getMailingListsAction({ commit }, { current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/operations/mailing-list?page=${page}&limit=${limit}`)

            commit('SET_MAILING_LISTS', response.data.data.data)

            return Promise.resolve(response)
        },
    },
}
