/* eslint-disable */
import axios from 'axios'

export default {

    namespaced: true,

    state: {
        member: {},
        tickets: [],
        reviews: [],
        members: [],
        bottles: [],
        careers: [],
        bookings: [],
        giftCards: [],
        pagination: {},
        reservations: [],
    },

    getters: {
        members(state) {
            return state.members
        },

        member(state) {
            return state.member
        },

        reservations(state) {
            return state.reservations
        },

        bookings(state) {
            return state.bookings
        },

        bottles(state) {
            return state.bottles
        },

        tickets(state) {
            return state.tickets
        },

        careers(state) {
            return state.careers
        },

        giftCards(state) {
            return state.giftCards
        },

        reviews(state) {
            return state.reviews
        },
    },

    mutations: {
        SET_MEMBERS(state, payload) {
            state.members = payload
        },

        SET_MEMBER(state, payload) {
            state.member = payload
        },

        SET_RESERVATIONS(state, payload) {
            state.reservations = payload
        },

        SET_BOOKINGS(state, payload) {
            state.bookings = payload
        },

        SET_BOTTLES(state, payload) {
            state.bottles = payload
        },

        SET_TICKETS(state, payload) {
            state.tickets = payload
        },

        SET_CAREERS(state, payload) {
            state.careers = payload
        },

        SET_GIFT_CARDS(state, payload) {
            state.giftCards = payload
        },

        SET_REVIEWS(state, payload) {
            state.reviews = payload
        },
    },

    actions: {

        async getMembersAction({ commit }, { current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/member?page=${page}&limit=${limit}`)

            commit('SET_MEMBERS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getMemberInfoAction({ commit }, id) {
            let response = await axios.get(`admin/member/${id}`)

            commit('SET_MEMBER', response.data.data)

            return Promise.resolve(response)
        },

        async getMemberReservationsAction({ commit }, { id, current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/member/${id}/reservations?page=${page}&limit=${limit}`)

            commit('SET_RESERVATIONS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getMemberBookingsAction({ commit }, { id, current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/member/${id}/bookings?page=${page}&limit=${limit}`)

            commit('SET_BOOKINGS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getMemberEventTicketOrdersAction({ commit }, { id, current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/member/${id}/tickets?page=${page}&limit=${limit}`)

            commit('SET_TICKETS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getMemberBottlesAction({ commit }, { id, current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/member/${id}/bottles?page=${page}&limit=${limit}`)

            commit('SET_BOTTLES', response.data.data.data)

            return Promise.resolve(response)
        },

        async getMemberCareersAction({ commit }, { id, current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/member/${id}/careers?page=${page}&limit=${limit}`)

            commit('SET_CAREERS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getMemberGiftCardsAction({ commit }, { id, current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/member/${id}/gift-cards?page=${page}&limit=${limit}`)

            commit('SET_GIFT_CARDS', response.data.data.data)

            return Promise.resolve(response)
        },

        async getMemberReviewsAction({ commit }, { id, current_page:page = 1, per_page:limit = 20 }) {
            let response = await axios.get(`admin/member/${id}/reviews?page=${page}&limit=${limit}`)

            commit('SET_REVIEWS', response.data.data.data)

            return Promise.resolve(response)
        },

    }

}
